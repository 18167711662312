import React from 'react';
import { Link } from 'gatsby';
import Layout from '../Layout';
import Seo from '../seo';
import SubPageWrapper from '../SubPageWrapper';
import Inner from '../Inner';
import Box from '../Box';
import * as Styles from '../../styles/pages/_post.module.scss';
import NewsPageWrapper from '../news-page-wraper';
import Title from '../Title';
import BACK_BTN from '../../images/news_back_btn.png';
import BackButton from '../back-button';
import { SectionTitle } from '../SectionTitle';
import { css } from '@emotion/react';

export default function PostTemplate(props) {
  const { newsId, title, body, publishedAt, category, thumbnail } = props;

  const breadcrumb = [
    { label: `お知らせ`, url: '/news' },
    { label: title, url: `/news/${newsId}` },
  ];

  const convertDateFormat = (dateStr) => {
    return dateStr.split('/').join('.');
  };

  return (
    <>
      <Seo
        url={`/news/${newsId}`}
        title="ニュース｜Uniforce（ユニフォース）株式会社"
        description="Uniforceに関する情報・お客様へのお知らせやIPOに関するセミナーなどのお知らせを掲載しています。"
        image="https://uniforce.co.jp/og-image.jpg"
        breadcrumbs={breadcrumb}
      />
      <Layout breadcrumb={breadcrumb} contact>
        <SubPageWrapper>
          <div className={Styles.post_page}>
            <SectionTitle
              enText="News"
              titleText="お知らせ詳細"
              css={css`
                position: relative;
                padding-top: 140px;
                z-index: 2;
                max-width: 1158px;
                margin: auto;
                @media (max-width: 1020px) {
                  margin-left: -25px;
                }
              `}
            />
            <div className={Styles.margin} />
            <Box>
              <time className={Styles.post_page__date}>
                {convertDateFormat(publishedAt)}
              </time>
              <h1 className={Styles.post_page__title}>{title}</h1>
              <div
                className={Styles.post_page__body}
                dangerouslySetInnerHTML={{
                  __html: `${body.replace(/\n/g, '<br />')}`,
                }}
              ></div>
            </Box>
            <div className={Styles.view_more_wrapper}>
              <Link to="/news/">
                <BackButton to="/news/" />
              </Link>
            </div>
          </div>
        </SubPageWrapper>
      </Layout>
    </>
  );
}
