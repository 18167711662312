import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { createClient } from 'microcms-js-sdk';
import PostTemplate from '../../../components/post/template';

export default function PostDraft({ location }) {
  const { contentId, draftKey } = queryString.parse(location.search);
  const [data, setData] = useState(null);

  useEffect(() => {
    const client = createClient({
      serviceDomain: 'aiir',
      apiKey: process.env.GATSBY_MICROCMS_API_KEY,
    });

    client
      .get({
        contentId,
        endpoint: 'news',
        queries: { draftKey },
      })
      .then((res) => {
        setData(res);
      });
  }, []);

  if (data === null) {
    return null;
  }

  return <PostTemplate newsId={contentId} {...data} />;
}
